.Landing-head{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    background: transparent;
    position: fixed;
    top: 0;
    left: 0;
    color: white;
    font-size: 20px;
    flex-direction: column;
}

.Landing-head>h4{
    /* background-color: #0c0d13; */
    padding: 10px;
    border-radius: 8px;
    text-align: center;
    margin: 0;
    font-family: monospace;
}

.Landing-head>h2{
    /* background-color: #0c0d13; */
    /* padding: 10px; */
    border-radius: 8px;
    text-align: center;
    margin: 0;
    font-family: monospace;
}

.Landing {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background: radial-gradient(ellipse at bottom, #0d1d31 0%, #0c0d13 100%);
    overflow: hidden;
  }
  
  .stars {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 120%;
    transform: rotate(-45deg);
  }
  
  .star {
    --star-color: white;
    --star-tail-length: 6em;
    --star-tail-height: 2px;
    --star-width: calc(var(--star-tail-length) / 6);
    --fall-duration: 9s;
    --tail-fade-duration: var(--fall-duration);
    position: absolute;
    top: var(--top-offset);
    left: 0;
    width: var(--star-tail-length);
    height: var(--star-tail-height);
    color: var(--star-color);
    background: linear-gradient(45deg, currentColor, transparent);
    border-radius: 50%;
    filter: drop-shadow(0 0 6px currentColor);
    transform: translate3d(104em, 0, 0);
    animation: fall var(--fall-duration) var(--fall-delay) linear infinite, tail-fade var(--tail-fade-duration) var(--fall-delay) ease-out infinite;
  }
  @media screen and (max-width: 750px) {
    .star {
      animation: fall var(--fall-duration) var(--fall-delay) linear infinite;
    }
  }
  .star:nth-child(1) {
    --star-tail-length: 7.35em;
    --top-offset: 31.97vh;
    --fall-duration: 8.998s;
    --fall-delay: 1.464s;
  }
  .star:nth-child(2) {
    --star-tail-length: 6.68em;
    --top-offset: 78.98vh;
    --fall-duration: 10.238s;
    --fall-delay: 2.734s;
  }
  .star:nth-child(3) {
    --star-tail-length: 7.2em;
    --top-offset: 82.76vh;
    --fall-duration: 7.708s;
    --fall-delay: 8.547s;
  }
  .star:nth-child(4) {
    --star-tail-length: 6.6em;
    --top-offset: 77.21vh;
    --fall-duration: 6.408s;
    --fall-delay: 4.721s;
  }
  .star:nth-child(5) {
    --star-tail-length: 7.43em;
    --top-offset: 63.67vh;
    --fall-duration: 8.924s;
    --fall-delay: 3.278s;
  }
  .star:nth-child(6) {
    --star-tail-length: 6em;
    --top-offset: 96.72vh;
    --fall-duration: 8.704s;
    --fall-delay: 7.501s;
  }
  .star:nth-child(7) {
    --star-tail-length: 6.6em;
    --top-offset: 27.68vh;
    --fall-duration: 8.178s;
    --fall-delay: 3.892s;
  }
  .star:nth-child(8) {
    --star-tail-length: 6.45em;
    --top-offset: 97.2vh;
    --fall-duration: 9.673s;
    --fall-delay: 4.271s;
  }
  .star:nth-child(9) {
    --star-tail-length: 6.21em;
    --top-offset: 92.63vh;
    --fall-duration: 6.883s;
    --fall-delay: 2.43s;
  }
  .star:nth-child(10) {
    --star-tail-length: 6.16em;
    --top-offset: 90.2vh;
    --fall-duration: 6.043s;
    --fall-delay: 9.412s;
  }
  .star:nth-child(11) {
    --star-tail-length: 5.39em;
    --top-offset: 13.96vh;
    --fall-duration: 11.643s;
    --fall-delay: 1.422s;
  }
  .star:nth-child(12) {
    --star-tail-length: 6.57em;
    --top-offset: 24.99vh;
    --fall-duration: 7.755s;
    --fall-delay: 2.956s;
  }
  .star:nth-child(13) {
    --star-tail-length: 7.43em;
    --top-offset: 47.89vh;
    --fall-duration: 10.96s;
    --fall-delay: 2.085s;
  }
  .star:nth-child(14) {
    --star-tail-length: 5.2em;
    --top-offset: 95.85vh;
    --fall-duration: 9.713s;
    --fall-delay: 0.401s;
  }
  .star:nth-child(15) {
    --star-tail-length: 5.82em;
    --top-offset: 0.31vh;
    --fall-duration: 6.405s;
    --fall-delay: 1.787s;
  }
  .star:nth-child(16) {
    --star-tail-length: 5.28em;
    --top-offset: 5.96vh;
    --fall-duration: 11.087s;
    --fall-delay: 9.439s;
  }
  .star:nth-child(17) {
    --star-tail-length: 7.14em;
    --top-offset: 49.98vh;
    --fall-duration: 9.941s;
    --fall-delay: 5.84s;
  }
  .star:nth-child(18) {
    --star-tail-length: 6.61em;
    --top-offset: 19.03vh;
    --fall-duration: 7.04s;
    --fall-delay: 5.635s;
  }
  .star:nth-child(19) {
    --star-tail-length: 7.2em;
    --top-offset: 87.99vh;
    --fall-duration: 11.482s;
    --fall-delay: 5.241s;
  }
  .star:nth-child(20) {
    --star-tail-length: 6.45em;
    --top-offset: 32.91vh;
    --fall-duration: 6.75s;
    --fall-delay: 6.787s;
  }
  .star:nth-child(21) {
    --star-tail-length: 6.03em;
    --top-offset: 61.97vh;
    --fall-duration: 6.004s;
    --fall-delay: 9.366s;
  }
  .star:nth-child(22) {
    --star-tail-length: 6.72em;
    --top-offset: 85.56vh;
    --fall-duration: 9.279s;
    --fall-delay: 1.258s;
  }
  .star:nth-child(23) {
    --star-tail-length: 6.29em;
    --top-offset: 30.65vh;
    --fall-duration: 7.168s;
    --fall-delay: 8.372s;
  }
  .star:nth-child(24) {
    --star-tail-length: 7.5em;
    --top-offset: 7.77vh;
    --fall-duration: 9.476s;
    --fall-delay: 5.548s;
  }
  .star:nth-child(25) {
    --star-tail-length: 6.11em;
    --top-offset: 62.1vh;
    --fall-duration: 6.645s;
    --fall-delay: 6.972s;
  }
  .star:nth-child(26) {
    --star-tail-length: 7.46em;
    --top-offset: 87.07vh;
    --fall-duration: 7.218s;
    --fall-delay: 5.149s;
  }
  .star:nth-child(27) {
    --star-tail-length: 6.32em;
    --top-offset: 54.02vh;
    --fall-duration: 10.82s;
    --fall-delay: 9.748s;
  }
  .star:nth-child(28) {
    --star-tail-length: 7.01em;
    --top-offset: 43.91vh;
    --fall-duration: 11.713s;
    --fall-delay: 8.611s;
  }
  .star:nth-child(29) {
    --star-tail-length: 6.21em;
    --top-offset: 29.17vh;
    --fall-duration: 10.009s;
    --fall-delay: 9.88s;
  }
  .star:nth-child(30) {
    --star-tail-length: 5.05em;
    --top-offset: 61.44vh;
    --fall-duration: 9.954s;
    --fall-delay: 4.243s;
  }
  .star:nth-child(31) {
    --star-tail-length: 5.57em;
    --top-offset: 52.69vh;
    --fall-duration: 10.16s;
    --fall-delay: 8.057s;
  }
  .star:nth-child(32) {
    --star-tail-length: 6.37em;
    --top-offset: 84.15vh;
    --fall-duration: 8.131s;
    --fall-delay: 7.293s;
  }
  .star:nth-child(33) {
    --star-tail-length: 6.65em;
    --top-offset: 41.78vh;
    --fall-duration: 11.493s;
    --fall-delay: 8.679s;
  }
  .star:nth-child(34) {
    --star-tail-length: 6.73em;
    --top-offset: 21.08vh;
    --fall-duration: 8.377s;
    --fall-delay: 0.191s;
  }
  .star:nth-child(35) {
    --star-tail-length: 6.08em;
    --top-offset: 76.5vh;
    --fall-duration: 10.605s;
    --fall-delay: 0.497s;
  }
  .star:nth-child(36) {
    --star-tail-length: 7.14em;
    --top-offset: 75.95vh;
    --fall-duration: 10.044s;
    --fall-delay: 6.016s;
  }
  .star:nth-child(37) {
    --star-tail-length: 5.77em;
    --top-offset: 56.65vh;
    --fall-duration: 10.042s;
    --fall-delay: 8.668s;
  }
  .star:nth-child(38) {
    --star-tail-length: 7.33em;
    --top-offset: 81.74vh;
    --fall-duration: 10.414s;
    --fall-delay: 3.396s;
  }
  .star:nth-child(39) {
    --star-tail-length: 5.44em;
    --top-offset: 3.65vh;
    --fall-duration: 11.495s;
    --fall-delay: 4.093s;
  }
  .star:nth-child(40) {
    --star-tail-length: 7.42em;
    --top-offset: 92.47vh;
    --fall-duration: 9.844s;
    --fall-delay: 7.726s;
  }
  .star:nth-child(41) {
    --star-tail-length: 6.05em;
    --top-offset: 2.87vh;
    --fall-duration: 9.182s;
    --fall-delay: 8.235s;
  }
  .star:nth-child(42) {
    --star-tail-length: 6.28em;
    --top-offset: 69.75vh;
    --fall-duration: 11.428s;
    --fall-delay: 6.895s;
  }
  .star:nth-child(43) {
    --star-tail-length: 5.46em;
    --top-offset: 91.67vh;
    --fall-duration: 11.296s;
    --fall-delay: 3.696s;
  }
  .star:nth-child(44) {
    --star-tail-length: 6.99em;
    --top-offset: 64.84vh;
    --fall-duration: 8.219s;
    --fall-delay: 5.686s;
  }
  .star:nth-child(45) {
    --star-tail-length: 5.29em;
    --top-offset: 20.09vh;
    --fall-duration: 9.443s;
    --fall-delay: 0.392s;
  }
  .star:nth-child(46) {
    --star-tail-length: 5.58em;
    --top-offset: 3.9vh;
    --fall-duration: 10.35s;
    --fall-delay: 1.024s;
  }
  .star:nth-child(47) {
    --star-tail-length: 5.93em;
    --top-offset: 51.46vh;
    --fall-duration: 8.27s;
    --fall-delay: 0.829s;
  }
  .star:nth-child(48) {
    --star-tail-length: 6.92em;
    --top-offset: 6.32vh;
    --fall-duration: 11.138s;
    --fall-delay: 5.532s;
  }
  .star:nth-child(49) {
    --star-tail-length: 6.67em;
    --top-offset: 8.4vh;
    --fall-duration: 6.741s;
    --fall-delay: 7.812s;
  }
  .star:nth-child(50) {
    --star-tail-length: 6em;
    --top-offset: 71.67vh;
    --fall-duration: 8.564s;
    --fall-delay: 4.718s;
  }
  .star::before, .star::after {
    position: absolute;
    content: "";
    top: 0;
    left: calc(var(--star-width) / -2);
    width: var(--star-width);
    height: 100%;
    background: linear-gradient(45deg, transparent, currentColor, transparent);
    border-radius: inherit;
    animation: blink 2s linear infinite;
  }
  .star::before {
    transform: rotate(45deg);
  }
  .star::after {
    transform: rotate(-45deg);
  }
  
  @keyframes fall {
    to {
      transform: translate3d(-30em, 0, 0);
    }
  }
  @keyframes tail-fade {
    0%, 50% {
      width: var(--star-tail-length);
      opacity: 1;
    }
    70%, 80% {
      width: 0;
      opacity: 0.4;
    }
    100% {
      width: 0;
      opacity: 0;
    }
  }
  @keyframes blink {
    50% {
      opacity: 0.6;
    }
  }

