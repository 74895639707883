.NormalSelect{
    width: 100%;
    max-width: 700px;
}

.NormalSelect-input{
    width: 100%;
    height: 45px;
    border-radius: 7px;
    font-size: 16px;
    margin: 5px;
    padding-left: 15px;
    padding-right: 15px;
    font-weight: bold;
    cursor: pointer;
}


.NormalSelect-input::placeholder{
    color: #00B6AB;
    opacity: 0.5;
}

.NormalSelect-validField{
    border: 2px solid #00B6AB;
    color: #00B6AB;
}

.NormalSelect-validField:focus{
    outline: 3px solid #00B6AB;
}

.NormalSelect-invalidFiled{
    border: 2px solid red;
    color: red;
}

.NormalSelect-invalidFiled:focus{
    outline: 3px solid red;
}

.NormalSelect-placeholder{
    color: #00b6aaa1;
}