.NormalButton{
    padding: 8px 10px;
    cursor: pointer;
    background-color: #5AAFBD;
    color: white;
    font-weight: bold;
    border-radius: 4px;
    margin: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}