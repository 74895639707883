.ImportColumnSelector{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
}

.ImportColumnSelector-text{
    color: #5AAFBD;
    font-size: 18px;
    font-weight: 600;
}

.ImportColumnSelector-label{
    color: white!important;
    background-color: #5AAFBD;
    font-size: 18px;
    /* font-weight: 600; */
    padding: 8px;
    margin: auto 20px;
    border-radius: 6px;
}

.ImportColumnSelector-select-wrapper{
    max-width: 200px;
    margin: auto 10px;
}

.ImportColumnSelector-example-text{
    border: 1px solid black;
    border-radius: 6px;
    min-height: 45px;
    color: #5AAFBD;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 10px;
    margin: auto 10px;
    background-color: #D9D9D9;
    width: 250px;
}