.Home-container{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    min-height: 100vh;
}

.Home-sidebar{
    flex: 1;
    max-width: 200px;
    height: 100%;
}

.Home-main-container{
    flex: 3;
    padding: 20px;
}

.Home-sidebar-logo{
    width: 80%;
    display: flex;
    border: 1px solid #5AAFBD;
    margin: 10%;
    border-radius: 20px;
}

@media only screen and (max-width: 600px) {
    .Home-sidebar{
        flex: 0;
        max-width: 200px;
    }
}