.ImportDataProdutos{
    min-width: 90%;
}

.ImportDataProdutos-header{
    min-width: 90%;
    padding: 20px 30px;
    background-color: #EDEDED;
    border-radius: 20px;
}

.ImportDataProdutos-header>h3{
    color: #5AAFBD;
    font-size: 28px;
}

.ImportDataProdutos-container{
    padding: 3%;
}

.ImportDataProdutos-footer{
    display: flex;
    width: 96%;
    justify-content: flex-end;
    align-items: flex-end;
    margin: 2%;
}

.ImportDataProdutos-footer-button{
    min-width: 130px;
}

.ImportDataProdutos-footer-button-warning{
    background-color: #F0BC37!important;
}

#ImportDataProdutos-csvInput{
    display: none;
}

#ImportDataProdutos-csvInputLabel{
    cursor: pointer;
    background-color: #5AAFBD;
    /* padding: 30px 80px; */
    margin-top: 10px;
    display: block;
    width: 180px;
    height: 65px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 7px;
}

#ImportDataProdutos-csvInputLabel>svg{
    font-size: 1.8rem;
    color: white;
}

.ImportDataProdutos-step2-button-div{
    display: flex;
    align-items: center;
}

.ImportDataProdutos-step2-button-div>h5{
    color: #5AAFBD;
    margin-left: 20px;
}

.ImportDataProdutos-error{
    margin: 0;
    color: red;
}

.ImportDataProdutos-local-text{
    color: #5AAFBD;
    font-weight: 600;
    font-size: 16px;
}

.ImportInfo-header-details{
    margin-top: 10px;
    min-width: 90%;
    padding: 10px 30px;
    background-color: #D9D9D9;
    border-radius: 10px;
    display: flex;
    flex-wrap: wrap;
}

.ImportInfo-header-details>h3{
    color: #5AAFBD;
    font-size: 18px;
    margin: 5px;
    margin-right: 35px;
}

.ImportDataProdutos-error-list-item{
    margin: 0;
}

.ImportDataProdutos-result-box{
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.ImportDataProdutos-result-box-left{
    flex: 3;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-end;
    padding-right: 20px;
}

.ImportDataProdutos-result-box-right{
    flex: 2;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    flex-direction: column;
}

.ImportDataProdutos-result-box-left-c1{
    display: flex;
    width: 100%;
    height: min-content;
}

.ImportDataProdutos-result-box-left-c1-t1{
    flex: 1;
    color: #5AAFBD;
    font-weight: bold;
    font-size: 20px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 0;
    margin-top: 10px;
}

.ImportDataProdutos-result-box-left-c1-t2{
    background-color: #5AAFBD;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    min-width: 100px;
    padding: 10px 20px;
    font-size: 20px;
    color: white;
    border-radius: 10px;
    margin: 0;
    margin-top: 10px;
}

.btn-danger{
    color: white;
    background-color: red;
}


.ImportDataProdutos-finish-button{
    min-width: 230px;
    padding: 15px!important;
    margin-bottom: 0!important;
}


.ImportDataProdutos-results-table{
    width: 100%;
    margin-top: 40px;
}

.ImportDataProdutos-results-table>thead>tr>td{
    margin: 10px;
    background-color: red;
    color: white;
    padding: 10px;
    border-radius: 8px;
    min-width: 40px;
    font-weight: bold;
    text-align: center;
}

.ImportDataProdutos-results-table>tbody>tr>td{
    margin: 0 10px;
    padding: 5px 10px;
    border-radius: 8px;
    font-weight: bold;
    font-size: 20px;
}

.ImportDataProdutos-results-table>tbody>tr>td:nth-child(1){
    text-align: center;
}