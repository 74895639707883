.App {
  text-align: center;
}

.text-danger{
  color: red!important;
}

.text-success{
  color: green!important;
}