.ImportInfo>p{
    color: #5AAFBD;
    font-weight: 600;
}

.ImportInfo-buttons{
    display: flex;
    flex-wrap: wrap;
}

.ImportInfo-button-products{
    background-color: #2D707B;
}

.ImportInfo-header{
    min-width: 90%;
    padding: 20px 30px;
    background-color: #EDEDED;
    border-radius: 10px;
}

.ImportInfo-header>h3{
    color: #5AAFBD;
    font-size: 28px;
}

.ImportInfo-header-details{
    margin-top: 10px;
    min-width: 90%;
    padding: 10px 30px;
    background-color: #D9D9D9;
    border-radius: 10px;
    display: flex;
    flex-wrap: wrap;
}

.ImportInfo-header-details>h3{
    color: #5AAFBD;
    font-size: 18px;
    margin: 5px;
    margin-right: 35px;
}

.ImportInfo-listItens>li{
    line-height: 30px;
}